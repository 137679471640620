import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, InfoBox, Polygon, useJsApiLoader } from "@react-google-maps/api";
import { calculateCenterPoint, getSearchQueryArray } from "../utils/utils";

const containerStyle = {
    width: "100%",
    maxHeight: "100%",
    height: "100vh",
};
const center = { lat: 43.076, lng: -107.2903 };

const Dashbaord = () => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyBSSHAzKzq7MDUeXr0y82SPYgz-YP1IxZ4",
    });

    const zoomRef = useRef(0);
    const [mapCenter, setMapCenter] = useState(center);
    const [loadingData, setLoadingData] = useState(true);

    const [label, setLabel] = useState("");
    const [showLabel, setShowLabel] = useState(true);

    const [map, setMap] = useState(null);
    const [searchedQuery, setSearchedQuery] = useState("");

    const [geoJsonData, setGeoJsonData] = useState([]);

    const [infoBoxes, setInfoBoxes] = useState([]);

    const onLoad = (map) => {
        const resp = getSearchQueryArray(window.location.search);

        const { label, showLabel, filteredQueryValues } = resp;

        setLabel(label);
        setShowLabel(showLabel);

        if (window.location.search) {
            if (filteredQueryValues.length === 1) {
                zoomRef.current = 9;

                for (let i = 0; i < geoJsonData.length; i++) {
                    const geoJsonItem = geoJsonData[i];

                    for (let j = 0; j < geoJsonItem.features.length; j++) {
                        const geoJsonFeature = geoJsonItem.features[j];

                        const polygonCoordinatesArr = geoJsonFeature.geometry.coordinates[0];

                        const details = geoJsonFeature.properties;

                        if (filteredQueryValues.includes(details.HUNTAREA.toString())) {
                            const center = calculateCenterPoint(polygonCoordinatesArr);
                            setMapCenter(center);
                            return;
                        }
                    }
                }
            }
        }

        setMap(map);
    };

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    const handleZoomChange = () => {
        if (map) {
            zoomRef.current = map.zoom;
        } else {
            zoomRef.current = 7;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const kmlResponse = await fetch("kml.json", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });

            const kmlData = await kmlResponse.json();
            const kmlUrls = kmlData.urls;

            try {
                
                // ------------------------------
                // MY MULTIPLE CALLS
                // ------------------------------
                const kmlPromiseArray = kmlUrls.map((url) => fetch(url));

                let urlsResults = await Promise.allSettled([...kmlPromiseArray]);
                let urlsData = await Promise.allSettled(
                    urlsResults
                        .filter((rslt) => rslt.status === "fulfilled")
                        .map((rslt) => rslt.value.json())
                );

                const geoJsonData = urlsData
                    .filter((data) => data.status === "fulfilled")
                    .map((data) => data.value);


                setGeoJsonData(geoJsonData );

                const { label, filteredQueryValues, showLabel } = getSearchQueryArray(
                    window.location.search
                );

                setLabel(label);
                setShowLabel(showLabel);

                if (filteredQueryValues) {
                    geoJsonData.forEach((geoJsonItem) => {
                        geoJsonItem?.features?.forEach((item) => {
                            const polygonCoordinatesArr = item.geometry.coordinates[0];

                            for (let i = 0; i < polygonCoordinatesArr.length; i++) {
                                const details = item.properties;

                                let position = calculateCenterPoint(polygonCoordinatesArr);

                                if (filteredQueryValues.includes(details.HUNTAREA.toString())) {
                                    setInfoBoxes((prevState) => {
                                        return [...prevState, { ...details, position }];
                                    });
                                }
                            }
                        });
                    });

                    setSearchedQuery(filteredQueryValues.join());
                }
            } catch (error) {
                console.log("Error fetching GeoJSON data:", error);
            } finally {
                setTimeout(() => {
                    setLoadingData(false);
                }, 0);
            }
        };

        fetchData();
    }, []);

    const handleMarkerClick = (event, details) => {
        setInfoBoxes((prevState) => {
            let infoBox = prevState.find((prevBox) => prevBox.HUNTAREA === details.HUNTAREA);

            if (infoBox) {
                return prevState;
            } else {
                return [
                    ...prevState,
                    {
                        ...details,
                        position: {
                            lat: Number(event.latLng?.lat()),
                            lng: Number(event.latLng?.lng()),
                        },
                    },
                ];
            }
        });
    };

    const handleCloseInfoBox = (event, details) => {
        event.stopPropagation();

        setInfoBoxes((prevState) => {
            let infoBox = prevState.find((prevBox) => prevBox.HUNTAREA === details.HUNTAREA);

            if (infoBox) {
                return prevState.filter((prevBox) => prevBox.HUNTAREA !== details.HUNTAREA);
            } else {
                return prevState;
            }
        });
    };

    const handleInputChange = (e) => {
        let value = e.target.value?.trim?.() || "";

        if (!value) {
            setSearchedQuery("");
            return;
        }

        setSearchedQuery(value.trim());
    };

    if (loadingData) return <h3>Loading...</h3>;

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            options={{
                zoom: zoomRef.current,
            }}
            onLoad={onLoad}
            onZoomChanged={handleZoomChange}
            onUnmount={onUnmount}
        >
            {geoJsonData.map((geoJsonItem) => {
                let data = geoJsonItem.features.map((item, index) => {
                    const polygonCoordinatesArr = item.geometry.coordinates[0][0];

                    const paths = polygonCoordinatesArr?.map(([lng, lat]) => ({
                        lat,
                        lng,
                    }));

                    const details = item.properties;

                    let showRed = !!(
                        searchedQuery &&
                        searchedQuery.split(",").includes(item.properties.HUNTAREA.toString())
                    );

                    return (
                        <Polygon
                            key={index}
                            paths={paths}
                            options={{
                                fillColor: showRed ? "rgba(255,0,0,0.5)" : "#AACB9B",
                                strokeColor: showRed ? "rgba(255,0,0,0.8)" : "rgba(0,0,0,0.8)",
                                strokeWeight: 1,
                            }}
                            onClick={(e) => handleMarkerClick(e, details)}
                        />
                    );
                });
                return [...data];
            })}

            {showLabel?.toUpperCase() !== "N" &&
                infoBoxes.map((infoBox) => {
                    return (
                        <InfoBox position={infoBox.position} key={infoBox.HUNTAREA}>
                            <div className="marker-infobox--container">
                                <p>
                                    {<b>{label || "HUNTAREA"} :</b>} {infoBox.HUNTAREA}
                                </p>

                                <button onClick={(e) => handleCloseInfoBox(e, infoBox)}>X</button>
                            </div>
                        </InfoBox>
                    );
                })}

            <section className="filter-container">
                <form className="filter-form" method="GET">
                    <div className="form-group">
                        <label htmlFor="unit-input">Search Unit No e.g 12 or 12, 63 etc</label>
                        <input
                            type="text"
                            name="unit"
                            id="unit"
                            onChange={handleInputChange}
                            value={searchedQuery}
                        />
                    </div>
                </form>
            </section>
        </GoogleMap>
    ) : (
        <></>
    );
};

export default Dashbaord;
