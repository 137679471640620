export function filterQuery(query) {
    return query
        .split(",")
        .map((val) => val.trim())
        .filter((val) => val);
}

export function getFilteredFeatures(features, values) {
    return features?.filter((item) => {
        let { properties } = item;

        for (let i = 0; i < values.length; i++) {
            const val = values[i];

            if (properties.HERDUNIT.toString().startsWith(val)) {
                return true;
            }
        }

        return false;
    });
}

export const calculateCenterPoint = (paths, offset = 0) => {
    let latSum = 0;
    let lngSum = 0;
    let numPoints = 0;

    paths.forEach((path) => {
        path.forEach(([lng, lat]) => {
            latSum += lat;
            lngSum += lng;
            numPoints++;
        });
    });

    const centroidLat = latSum / numPoints;
    const centroidLng = lngSum / numPoints;

    return { lat: centroidLat - offset, lng: centroidLng - offset };
};

export function getSearchQueryArray(searchedQuery) {
    if (searchedQuery) {
        let query = searchedQuery.substring(1)?.trim();
        let queryArray = query.split("&").filter((val) => val.trim());
        let result = {};

        for (let i = 0; i < queryArray.length; i++) {
            const [key, value] = queryArray[i].split("=");
            result[key] = value;
        }

        let filteredQueryValues = [];

        if (result.unit) {
            if (result.unit?.includes("%2C")) {
                result.unit = result.unit.replace("%2C", ",");
            }

            filteredQueryValues = result.unit?.includes(",")
                ? filterQuery(result.unit)
                : [result.unit];
        }

        return {
            label: result.label,
            showLabel: result.showlabel,
            filteredQueryValues,
        };
    }
    return "";
}
